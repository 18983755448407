import React from 'react';
import './About.css';

export const About = () => {
    return (
        <section className="about" id="about">
            <h2 className="about-title">About us</h2>
            <div className="about-content">
                <div className="about-content_text">
                    <p className="first-p">We believe the future of healthcare to be tailored to perfection.</p>
                    <p>Muro Hybrid Design creates models through software optimization and the selection of biomaterials to harmonize in the body.</p>
                    <p className='last-p'>
                        <em>
                        Hybrid- a thing made by combining two different elements; a mixture. 
                            <span>
                                Biology + Technology
                            </span>
                        </em>
                    </p>
                </div>
                <div className="about-content_images">
                    <img
                        className="about-photo about-photo1"
                        src="./assets/cubos.jpeg"
                        alt="Products"
                    />
                    <img
                        className="about-photo about-photo2"
                        src="./assets/caja.jpeg"
                        alt="Products"
                    />
                    <img
                        className="about-photo about-photo3"
                        src="./assets/corse.jpeg"
                        alt="Products"
                    />
                </div>
            </div>
      </section>
    )
}
