import React from 'react';
import classes from './Hero.module.css';

export const Hero = () => {
    return (
        <header>
            <div className={classes["hero-content"]}>
                <img className={classes["hero-image"]} src="./assets/hero.jpg" alt="Hero" />
                <div className={classes["hero-text"]}>
                    <p className={classes["hero-phrase"]}>DESIGNING <span>SOLUTIONS</span></p>
                </div>
            </div>
        </header>
    )
}
