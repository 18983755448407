import React from 'react';
import './Contact.css';

export const Contact = () => {
    return (
        <section className="contact" id="contact">
        <div className="contact-content" style={{backgroundImage: 'url(./assets/contact.jpg)'}}>
          <div className="contact-content_form">
            <form action="https://formsubmit.co/admin@murohybrid.com" method="POST">
              <div>
                <h2 className="contact-title">Contact us</h2>
              </div>
              <div className="form_group">
                <input
                  type="text"
                  className="form_input"
                  placeholder="Full Name*"
                  name="Name"
                  id="name"
                  required
                />
              </div>
              <div className="form_group">
                <input
                  type="email"
                  className="form_input"
                  placeholder="Email Address*"
                  name="Email"
                  id="email"
                  required
                />
              </div>
              <div className="form_group">
                <input
                  type="tel"
                  className="form_input"
                  placeholder="Phone Number*"
                  name="Phone"
                  id="phone"
                  required
                />
              </div>
              <div className="form_group">
                <textarea
                  className="form_input form_textarea"
                  name="Subject"
                  id="subject"
                  cols="20"
                  rows="5"
                  placeholder="Subject*"
                  required
                ></textarea>
              </div>
              <button className="submit-button" type="submit">Submit</button>
            </form>
          </div>
        </div>
      </section>
    )
}
