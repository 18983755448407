import React from 'react';
import classes from './Navbar.module.css';

export const Navbar = () => {
    return (
        <nav className={classes.nav}>
            <div className={classes["nav-content"]}>
                <div className={classes["nav-logo"]}>
                    <img className={classes["nav-logo_image"]} src="./assets/logo.png" alt="Logo"/>
                </div>
                <div className={classes["nav-links"]}>
                    <a className={classes["nav-content_link"]} href="#about">About us</a>
                    <a className={classes["nav-content_link"]} href="#contact">Contact us</a>
                </div>
            </div>
        </nav>
    )
}
