import './index.css';
import { Hero } from './components/Hero';
import { Navbar } from './components/Navbar';
import { About } from './components/About';
import { Contact } from './components/Contact';

function App() {
  return (
    <>
      <Navbar />
      <Hero />
      <main>
        <About />
        <Contact />
      </main>
    </>
  );
}

export default App;
